<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="form.code" placeholder="权限值" />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="名称" />
        </a-form-model-item>

        <a-space style="margin-top: 4px">
          <a-button type="primary" @click="query">查询</a-button>
          <a-button @click="reset">重置</a-button>
        </a-space>

        <private-button
          class="float-right"
          path="add"
          type="primary"
          size="default"
        >
          <span>新建</span>
        </private-button>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        @change="onChange"
        :loading="loading"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column
          key="code"
          title="权限值"
          data-index="code"
        ></a-table-column>
        <a-table-column
          key="group"
          title="所属组"
          data-index="groupName"
        ></a-table-column>
        <a-table-column
          key="name"
          title="名称"
          data-index="name"
        ></a-table-column>

        <a-table-column key="module" title="所属模块">
          <template slot-scope="text">
            <div v-if="text.module">
              {{ text.module.name }}
            </div>
          </template>
        </a-table-column>
        <a-table-column
          key="path"
          title="接口路径"
          data-index="path"
        ></a-table-column>
        <a-table-column
          key="remarks"
          title="描述"
          data-index="remarks"
        ></a-table-column>
        <a-table-column
          key="createUserName"
          title="创建者"
          data-index="createUserName"
          align="center"
        ></a-table-column>
        <a-table-column
          key="createAt"
          title="创建时间"
          data-index="createAt"
          align="center"
        ></a-table-column>
        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <private-button path="edit" :params="{ uuid: text.uuid }">
              <span>编辑</span>
            </private-button>

            <private-button
              path="delete"
              routeType="method"
              danger
              @clickButton="deleteText(text)"
            >
              <span>删除</span>
            </private-button>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/setting/permission";

export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize,
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个权限吗？",
        onOk() {
          remove({
            uuid: text.uuid,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
